import React from 'react';
import Icon from '@soosap/sushi/Icon';

import { FoodItem, Offers, Variants } from 'server/src/app/carte/types';
import { Position } from 'server/src/app/order/types';
import styles from '../../Summary.module.scss';

export interface Props {
  position: Position;
  foodItem: FoodItem;
  offers: Offers;
  icons: {
    [iconName: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  };
}

const IndividualPosition: React.FC<Props> = ({
  position,
  foodItem,
  offers,
  icons,
}) => {
  const variants: Variants = position.variants;
  const positionAmount =
    Object.keys(variants).length > 0
      ? Object.values(variants).reduce((prev, curr) => {
          return prev + curr.delta;
        }, (offers[position.productId] || {}).quotes['1-x'].price)
      : offers[position.productId].quotes['1-x'].price;

  return (
    <React.Fragment>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--body`]
        } ${styles[`Summary__cell--1`]}`}
      >
        <span className={styles[`Summary__label`]}>{foodItem.title}</span>
        <span className={styles[`Summary__variants`]}>
          {Object.values(variants || [])
            .sort((a, b) => a.position - b.position)
            .map(variant =>
              variant.iconName ? (
                <Icon
                  key={variant.id}
                  className={styles[`Summary__icon`]}
                  svg={icons[variant.iconName]}
                />
              ) : (
                `(${variant.title})`
              )
            )}
        </span>
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--body`]
        } ${styles[`Summary__cell--2`]}`}
      >{`${position.quantity}`}</div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--body`]
        } ${styles[`Summary__cell--3`]}`}
      >
        {(positionAmount / 100).toFixed(2).replace('.', ',')}
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--body`]
        } ${styles[`Summary__cell--4`]}`}
      >
        {((positionAmount / 100) * position.quantity)
          .toFixed(2)
          .replace('.', ',')}
      </div>
    </React.Fragment>
  );
};

export default IndividualPosition;
