import { Environment } from '../../types';
import { ProductType, Variants } from '../carte/types';

export enum PaymentMethod {
  CASH = 'cash',
  CREDITCARD = 'creditcard',
  GIFTCARD = 'giftcard',
  BITCOIN = 'bitcoin',
}

export enum HandoverMethod {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

export interface Position {
  id: string;
  productId: string;
  productType: ProductType;
  quantity: number;
  includedAt?: number;
  variants: Variants;
}

export interface Basket {
  [positionId: string]: Position;
}

export enum BasketStatus {
  SELECTION = 'selection',
  HANDOVER = 'handover',
  PAYMENT = 'payment',
}

// Take Away: QUEUED => STARTED => FINISHED => CLAIMED
// Delivery: QUEUED => STARTED => SHIPPED => DELIVERED

export enum OrderStatus {
  QUEUED = 'queued', // Vorgemerkt
  STARTED = 'started', // In Bearbeitung
  FINISHED = 'finished', // Fertig, "Bitte abholen!"
  CLAIMED = 'claimed', // Abgeholt, "Guten Appetit!"
  SHIPPED = 'shipped', // Zu Ihnen unterwegs
  DELIVERED = 'delivered', // Ausgeliefert, "Guten Appetit!"
}

export interface Order {
  id?: string;
  env: Environment;
  status: OrderStatus;
  customerName: string;
  deliveryAddress?: string;
  phoneNumber?: string;
  orderedAt: number;
  amount: number;
  paymentMethod: PaymentMethod;
  message?: string;
  basket: Basket;
  handoverMethod: HandoverMethod;
  handoverAt: number;
  freeDeliveryApplies?: boolean;
}

export interface Orders {
  [orderId: string]: Order;
}

export interface PlaceOrderReqBody {
  basket: Basket;
  creditCardOwner?: string;
  customerName?: string;
  deliveryAddress?: string;
  handoverAt: string;
  handoverMethod: HandoverMethod;
  message?: string;
  paymentMethod: PaymentMethod;
  phoneNumber?: string;
  stripePaymentMethodId?: string;
  total: number;
}
