import { useEffect, useState } from 'react';

const firebaseConfig = {
  apiKey: 'AIzaSyAT6llgf1pwGI6_5mJtSGE2fCkvFEAeT4U',
  authDomain: 'thamarai-1c2b7.firebaseapp.com',
  databaseURL: 'https://thamarai-1c2b7.firebaseio.com',
  projectId: 'thamarai-1c2b7',
  storageBucket: 'thamarai-1c2b7.appspot.com',
  messagingSenderId: '58513400215',
  appId: '1:58513400215:web:8d3afe659733df64eae4d2',
};

// Singleton pattern
let firebaseInstance: any;
const getFirebase = (firebase: any) => {
  if (firebaseInstance) {
    return firebaseInstance;
  }

  firebase.initializeApp(firebaseConfig);
  firebaseInstance = firebase;

  return firebaseInstance;
};

interface UseFirebaseProps {
  firebase: any;
}

const useFirebase = (): UseFirebaseProps => {
  const [firebase, setFirebase] = useState();

  useEffect(() => {
    async function initializeFirebase() {
      const lazyFirebaseApp = (await import('firebase/app')).default;
      await import('firebase/database');

      setFirebase(getFirebase(lazyFirebaseApp));
    }

    initializeFirebase();
  }, []);

  return { firebase };
};

export default useFirebase;
