import { selector } from 'recoil';

import deliveryHoursState from './deliveryHours';

import moment from 'moment';

export default selector<string>({
  key: 'deliveryNextOpening',
  get: ({ get }) => {
    const deliveryHours = get(deliveryHoursState);

    let day = moment().day();

    // check if we are opening later in the day
    const todayOpeningHours = Object.values(deliveryHours)[
      day === 0 ? 6 : day - 1
    ];

    // check when the next day would be on which we open
    let nextDayOpeningHours = Object.values(deliveryHours)[day];

    while (
      !nextDayOpeningHours.lunchActive &&
      !nextDayOpeningHours.dinnerActive
    ) {
      day = day === 6 ? 0 : day + 1;
      nextDayOpeningHours = Object.values(deliveryHours)[day];
    }

    const delta = nextDayOpeningHours.position - todayOpeningHours.position;

    return delta === 1
      ? 'morgen'
      : delta === 2
      ? 'übermorgen'
      : nextDayOpeningHours.label;
  },
});
