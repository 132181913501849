import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useSpring, animated } from 'react-spring';
import createFocusFirstErrorDecorator from 'final-form-focus';
import Form from '@soosap/sushi/Form';

import { BasketStatus } from 'server/src/app/order/types';
import { HandoverFormValues, handoverFormValuesState } from 'state/placeOrder';
import basketOpenState from 'state/basketOpen';
import handoverMethodState from 'state/handoverMethod';
import basketStatusState, { prevBasketStatusState } from 'state/basketStatus';

import CallToAction from '../CallToAction';
import PickupOrDelivery from './components/PickupOrDelivery';
import styles from './Handover.module.scss';

const focusFirstError = createFocusFirstErrorDecorator();

export interface Props {}

const Handover: React.FC<Props> = () => {
  const [formValues, setFormValues] = useRecoilState(handoverFormValuesState);
  const handoverMethod = useRecoilValue(handoverMethodState);
  const setBasketStatus = useSetRecoilState(basketStatusState);
  const [prevBasketStatus, setPrevBasketStatus] = useRecoilState(
    prevBasketStatusState
  );
  const basketOpen = useRecoilValue(basketOpenState);

  const formStyles = useSpring({
    from: {
      left: prevBasketStatus === BasketStatus.SELECTION ? '70%' : '-10%',
      opacity: 0,
      maxHeight: '100vh',
    },
    to: {
      left: '0',
      opacity: basketOpen ? 1 : 0,
      maxHeight: basketOpen ? '100vh' : '0vh',
    },
  });

  const marginStyles = useSpring({
    from: {
      marginTop: '0rem',
    },
    to: {
      marginTop: basketOpen ? '0rem' : '-1.5rem',
    },
    config: {
      friction: 10,
    },
  });

  const onSubmit = (values: HandoverFormValues) => {
    setFormValues(values);
    setPrevBasketStatus(BasketStatus.HANDOVER);
    setBasketStatus(BasketStatus.PAYMENT);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...formValues, handoverMethod }}
      decorators={[focusFirstError]}
      render={({ handleSubmit }) => (
        <animated.form
          id="handoverForm"
          onSubmit={handleSubmit}
          className={styles[`Handover`]}
          style={{ ...formStyles, ...marginStyles }}
        >
          <PickupOrDelivery />
          <CallToAction />
        </animated.form>
      )}
    />
  );
};

export default Handover;
