import React from 'react';
import { useRecoilState } from 'recoil';
import Button from '@soosap/sushi/Button';
import { IconSize } from '@soosap/sushi/Icon';

import ArrowDownIcon from 'icons/ArrowDown';
import basketOpenState from 'state/basketOpen';
import styles from './Collapse.module.scss';

export interface Props {}

const Collapse: React.FC<Props> = () => {
  const [open, setOpen] = useRecoilState(basketOpenState);

  return (
    <Button
      className={styles[`Collapse`]}
      onClick={() => setOpen(!open)}
      icon={ArrowDownIcon}
      iconSize={IconSize.LARGE}
      iconClassName={`${styles[`Collapse__icon`]} ${
        open ? '' : styles[`Collapse__icon--collapsed`]
      }`}
    />
  );
};

export default Collapse;
