import { graphql, useStaticQuery } from 'gatsby';

export default () =>
  useStaticQuery<GatsbyTypes.BasketQuery>(graphql`
    query Basket {
      pickupHours: hours(id: { eq: "takeAway" }) {
        monday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        tuesday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        wednesday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        thursday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        friday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        saturday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        sunday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
      }
      deliveryHours: hours(id: { eq: "delivery" }) {
        monday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        tuesday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        wednesday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        thursday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        friday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        saturday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
        sunday {
          id
          label
          abbrev
          lunchActive
          lunchFrom
          lunchTo
          dinnerActive
          dinnerFrom
          dinnerTo
          position
        }
      }
    }
  `);
