import { selector } from 'recoil';

import subtotalAfterShippingState from 'state/subtotalAfterShipping';

export default selector({
  key: 'subtotalAfterGiftCard',
  get: ({ get }) => {
    const subtotalAfterShipping = get(subtotalAfterShippingState);

    return subtotalAfterShipping;
  },
});
