import React, { useEffect, useState, useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Button, { ButtonTheme } from '@soosap/sushi/Button';
import Spinner, { SpinnerSize, SpinnerTheme } from '@soosap/sushi/Spinner';

import { BasketStatus, HandoverMethod } from 'server/src/app/order/types';
import basketStatusState, { prevBasketStatusState } from 'state/basketStatus';
import basketOpenState from 'state/basketOpen';
import handoverMethodState from 'state/handoverMethod';
import paymentMethodState from 'state/paymentMethod';
import submittingState from 'state/submitting';
import totalState from 'state/total';
import pickupTimeSlotsState from 'state/pickupTimeSlots';
import deliveryTimeSlotsState from 'state/deliveryTimeSlots';
import { paymentFormValuesState } from 'state/placeOrder';

import Collapse from '../Collapse';
import styles from './CallToAction.module.scss';
import { PaymentMethod } from 'server/src/app/giftcard/types';

const STRETCH_TRESHOLD = 100;

export interface Props {}

const CallToAction: React.FC<Props> = () => {
  const [basketStatus, setBasketStatus] = useRecoilState(basketStatusState);
  const setBasketOpen = useSetRecoilState(basketOpenState);
  const setPrevBasketStatus = useSetRecoilState(prevBasketStatusState);
  const setPaymentFormValues = useSetRecoilState(paymentFormValuesState);
  const handoverMethod = useRecoilValue(handoverMethodState);
  const paymentMethod = useRecoilValue(paymentMethodState);
  const submitting = useRecoilValue(submittingState);
  const total = useRecoilValue(totalState);
  const pickupTimeSlots = useRecoilValue(pickupTimeSlotsState);
  const deliveryTimeSlots = useRecoilValue(deliveryTimeSlotsState);
  const [shouldStretchHeight, setShouldStretchHeight] = useState<boolean>(
    false
  );

  const handleTouch = useCallback(() => {
    setShouldStretchHeight(
      window.screen.height - window.innerHeight < STRETCH_TRESHOLD
    );
  }, []);

  useEffect(() => {
    window.addEventListener('touchstart', handleTouch);
    return () => window.addEventListener('touchstart', handleTouch);
  }, []);

  useEffect(() => {
    window.addEventListener('touchmove', handleTouch);
    return () => window.addEventListener('touchmove', handleTouch);
  }, []);

  return basketStatus === BasketStatus.SELECTION ? (
    <Button.Group
      className={`${styles[`CallToAction`]} ${
        shouldStretchHeight ? styles[`CallToAction--stretched`] : ''
      }`}
    >
      <Button
        theme={ButtonTheme.DARK}
        borderless
        className={`${styles[`CallToAction__button`]} ${
          styles[`CallToAction__button--forward`]
        }`}
        classNameInner={styles[`CallToAction__button-inner`]}
        onClick={() => {
          setPrevBasketStatus(BasketStatus.SELECTION);
          setBasketStatus(BasketStatus.HANDOVER);
          setBasketOpen(true);
        }}
      >
        <div>Weiter zu</div>
        <div>
          ➡️ &nbsp;Abholen&nbsp;<i>oder</i>&nbsp;Liefern
        </div>
      </Button>
      <Collapse />
    </Button.Group>
  ) : basketStatus === BasketStatus.HANDOVER ? (
    <Button.Group
      className={`${styles[`CallToAction`]} ${
        shouldStretchHeight ? styles[`CallToAction--stretched`] : ''
      }`}
    >
      <Button
        theme={ButtonTheme.LIGHT}
        className={`${styles[`CallToAction__button`]} ${
          styles[`CallToAction__button--back`]
        }`}
        classNameInner={styles[`CallToAction__button-inner`]}
        onClick={() => {
          setBasketOpen(true);
          setPrevBasketStatus(BasketStatus.HANDOVER);
          setBasketStatus(BasketStatus.SELECTION);
        }}
      >
        <div>Zurück zur</div>
        <div>Übersicht</div>
      </Button>
      <Button
        theme={ButtonTheme.DARK}
        borderless
        className={`${styles[`CallToAction__button`]} ${
          styles[`CallToAction__button--forward`]
        }`}
        classNameInner={styles[`CallToAction__button-inner`]}
        disabled={
          handoverMethod === HandoverMethod.PICKUP
            ? pickupTimeSlots.length === 0
            : deliveryTimeSlots.length === 0
        }
        type="submit"
        onClick={() => setBasketOpen(true)}
      >
        <div>{(total / 100).toFixed(2).replace('.', ',')} EUR</div>
        <div>➡️ &nbsp;Bezahlung</div>
      </Button>
      <Collapse />
    </Button.Group>
  ) : basketStatus === BasketStatus.PAYMENT ? (
    <Button.Group
      className={`${styles[`CallToAction`]} ${
        shouldStretchHeight ? styles[`CallToAction--stretched`] : ''
      }`}
    >
      <Button
        theme={ButtonTheme.LIGHT}
        className={`${styles[`CallToAction__button`]} ${
          styles[`CallToAction__button--back`]
        }`}
        classNameInner={styles[`CallToAction__button-inner`]}
        onClick={() => {
          setPrevBasketStatus(BasketStatus.PAYMENT);
          setBasketStatus(BasketStatus.HANDOVER);

          const customerName =
            (document.getElementById('customerName') as HTMLInputElement)
              ?.value ?? '';
          const phoneNumber =
            (document.getElementById('phoneNumber') as HTMLInputElement)
              ?.value ?? '';
          const rememberPaymentCash =
            (document.getElementsByName(
              'rememberPaymentCash'
            )[0] as HTMLInputElement)?.value === 'true'
              ? true
              : false;

          setPaymentFormValues((prev) => ({
            ...prev,
            customerName,
            phoneNumber,
            rememberPaymentCash,
          }));
          setBasketOpen(true);
        }}
      >
        <div>Zurück zu</div>
        <div>
          {handoverMethod === HandoverMethod.DELIVERY
            ? 'Lieferung'
            : 'Abholung'}
        </div>
      </Button>
      <Button
        theme={ButtonTheme.DARK}
        borderless
        className={`${styles[`CallToAction__button`]} ${
          styles[`CallToAction__button--forward`]
        }`}
        classNameInner={styles[`CallToAction__button-inner`]}
        type="submit"
        onClick={() => setBasketOpen(true)}
      >
        <div>{`${(total / 100).toFixed(2).replace('.', ',')} EUR`}</div>
        <div>
          🍴&nbsp;
          {submitting ? (
            <>
              <Spinner
                className={styles[`CallToAction__spinner`]}
                theme={SpinnerTheme.LIGHT}
                size={SpinnerSize.SMALL}
              />
              <span>In Bearbeitung</span>
            </>
          ) : (
            `${paymentMethod === PaymentMethod.CASH ? 'Bestellen' : 'Bezahlen'}`
          )}
        </div>
      </Button>
      <Collapse />
    </Button.Group>
  ) : null;
};

export default CallToAction;
