import { graphql, useStaticQuery } from 'gatsby';

export default () =>
  useStaticQuery<GatsbyTypes.HeadquarterQuery>(graphql`
    query Headquarter {
      headquarter: file(
        relativePath: { eq: "about/about-thilagam-restaurant.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
