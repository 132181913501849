import React from 'react';

import styles from '../../Summary.module.scss';

export interface Props {
  deliveryCost: number;
  freeDeliveryApplies: boolean;
}

const DeliveryCost: React.FC<Props> = ({
  deliveryCost,
  freeDeliveryApplies,
}) => {
  return (
    <>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--intermediate`]
        } ${styles[`Summary__cell--1`]}`}
      >
        Lieferkosten
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--intermediate`]
        } ${styles[`Summary__cell--2`]}`}
      >
        {freeDeliveryApplies
          ? `Kostenfrei`
          : `${(deliveryCost / 100).toFixed(2).replace('.', ',')} EUR`}
      </div>
    </>
  );
};

export default DeliveryCost;
