import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import { useSpring, animated } from 'react-spring';
import createFocusFirstErrorDecorator from 'final-form-focus';
import {
  StripeCardNumberElement,
  StripeCardExpiryElement,
  StripeCardCvcElement,
} from '@stripe/stripe-js';
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import Form from '@soosap/sushi/Form';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import IconRadioField from '@soosap/sushi/Form/IconRadioField';

import CreditCardIcon from 'icons/CreditCard';
import GiftCardIcon from 'icons/GiftCard';
import BitcoinIcon from 'icons/Bitcoin';
import PayWithEuroIcon from 'icons/PayWithEuro';
import { PaymentFormValues } from 'state/placeOrder';
import paymentMethodState from 'state/paymentMethod';

import { BasketStatus, PaymentMethod } from 'server/src/app/order/types';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import FoodIcon from 'icons/Food';
import WarningIcon from 'icons/Warning';
import basketState from 'state/basket';
import basketDefaultTabIndexState from 'state/basketDefaultTabIndex';
import offersState, { initialOffersState } from 'state/offers';
import basketStatusState, { prevBasketStatusState } from 'state/basketStatus';
import placeOrderState, { paymentFormValuesState } from 'state/placeOrder';
import orderIdsState from 'state/orderIds';
import basketOpenState from 'state/basketOpen';
import submittingState from 'state/submitting';

import Bitcoin from './components/Bitcoin';
import Cash from './components/Cash';
import CreditCard from './components/CreditCard';
import GiftCard from './components/GiftCard';
import styles from './Payment.module.scss';
import { Environment } from 'server/src/types';
import CallToAction from '../CallToAction';

const { GATSBY_BACKEND_URL } = process.env;
const isClient = typeof window !== 'undefined';
const focusFirstError = createFocusFirstErrorDecorator();

export interface Props {}

const Payment: React.FC<Props> = () => {
  const { addToast } = useToasts();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethod, setPaymentMethod] = useRecoilState(paymentMethodState);
  const [formValues, setFormValues] = useRecoilState(paymentFormValuesState);
  const prevBasketStatus = useRecoilValue(prevBasketStatusState);
  const data = useRecoilValue(placeOrderState);
  const resetBasket = useResetRecoilState(basketState);
  const resetBasketStatus = useResetRecoilState(basketStatusState);
  const resetPrevBasketStatus = useResetRecoilState(prevBasketStatusState);
  const initialOffers = useRecoilValue(initialOffersState);
  const setOffers = useSetRecoilState(offersState);
  const setSubmitting = useSetRecoilState(submittingState);
  const setBasketDefaultTabIndex = useSetRecoilState(
    basketDefaultTabIndexState
  );
  const setOrderIds = useSetRecoilState(orderIdsState);
  const basketOpen = useRecoilValue(basketOpenState);
  const [creditCardNumberRef, setCreditCardNumberRef] = useState<
    StripeCardNumberElement
  >();
  const [creditCardExpiryRef, setCreditCardExpiryRef] = useState<
    StripeCardExpiryElement
  >();
  const [creditCardCvcRef, setCreditCardCvcRef] = useState<
    StripeCardCvcElement
  >();
  const formStyles = useSpring({
    from: {
      left: prevBasketStatus === BasketStatus.HANDOVER ? '70%' : '-10%',
      opacity: 0,
      maxHeight: '100vh',
    },
    to: {
      left: '0',
      opacity: basketOpen ? 1 : 0,
      maxHeight: basketOpen ? '100vh' : '0vh',
    },
  });
  const marginStyles = useSpring({
    from: {
      marginTop: '0rem',
    },
    to: {
      marginTop: basketOpen ? '0rem' : '-1rem',
    },
    config: {
      friction: 10,
    },
  });

  const onSubmit = async (values: PaymentFormValues) => {
    let res;
    let orderId: string = '';
    const reqData = { ...data, ...values };

    setFormValues(values);
    setSubmitting(true);

    try {
      switch (paymentMethod) {
        case PaymentMethod.BITCOIN:
          // TODO: Not yet implemented
          break;
        case PaymentMethod.GIFTCARD:
          // TODO: Not yet implemented
          break;
        case PaymentMethod.CREDITCARD:
          // a) stripe.js
          if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            throw new Error('stripe.js not loaded');
          }

          // b) cardElement
          const cardElement = elements.getElement(CardNumberElement);
          if (!cardElement) {
            throw new Error('No CardElement found');
          }

          // c) paymentMethod
          const stripePaymentMethod = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
              name: values.creditCardOwner,
            },
          });

          if (!stripePaymentMethod.paymentMethod || stripePaymentMethod.error) {
            throw new Error(
              stripePaymentMethod.error
                ? stripePaymentMethod.error.message
                : 'No payment method was returned'
            );
          }

          // c) paymentConfirmation
          res = await axios.post(`${GATSBY_BACKEND_URL}/api/orders/place`, {
            ...reqData,
            stripePaymentMethodId: stripePaymentMethod.paymentMethod.id,
            basket: data.basket,
          });

          orderId = res.data.orderId;
          break;
        case PaymentMethod.CASH:
        default:
          res = await axios.post(
            `${GATSBY_BACKEND_URL}/api/orders/place`,
            reqData
          );

          orderId = res.data.orderId;
          break;
      }

      // reset
      resetBasket();
      setOffers(initialOffers);

      if (paymentMethod === PaymentMethod.CREDITCARD) {
        try {
          creditCardNumberRef && creditCardNumberRef.clear();
          creditCardExpiryRef && creditCardExpiryRef.clear();
          creditCardCvcRef && creditCardCvcRef.clear();
        } catch (e) {}
      }

      window.scrollTo(0, 0);

      // userNotification
      addToast(`Bestellung erfolgreich! Order #${orderId}`, {
        theme: ToastTheme.SUCCESS,
        autoDismiss: false,
        icon: FoodIcon,
      });

      // populate basket tabs and get rid of the old ones
      setOrderIds(() => {
        const orderIds = (localStorage.getItem('orderIds') ?? '').split(',');

        const recentOrderItems = orderIds.filter((orderItem) => {
          const [orderId, orderedAt] = orderItem.split(':');

          return moment()
            .subtract(1, 'day')
            .isBefore(moment(parseInt(orderedAt)));
        });

        return [`${orderId}:${Date.now()}`, ...recentOrderItems];
      });
      resetBasketStatus();
      resetPrevBasketStatus();
      setBasketDefaultTabIndex(0);

      // tagManager
      if (isClient && process.env.NODE_ENV === Environment.PRODUCTION) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'call-to-action',
          category: 'Order',
          action: 'Place',
          value: data.total / 100,
          label: `${(data.total / 100).toFixed(2)} EUR`,
        });
      }
    } catch (e) {
      addToast(`Fehler! Bitte anrufen ☎ 07131 405 11 70`, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }

    setSubmitting(false);
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ ...formValues, paymentMethod }}
      decorators={[focusFirstError]}
      render={({ handleSubmit }) => (
        <animated.form
          id="paymentForm"
          onSubmit={handleSubmit}
          style={{ ...formStyles, ...marginStyles }}
          className={styles[`Payment`]}
        >
          <Title>Bezahlung</Title>
          <Container.Inner className={styles['Payment__container']}>
            <IconRadioField
              name="paymentMethod"
              className={styles['Payment__methods']}
            >
              <IconRadioField.Option
                id={PaymentMethod.CASH}
                svg={PayWithEuroIcon}
                title="bei Abholung"
                onChange={(id) => setPaymentMethod(id as PaymentMethod)}
              />
              <IconRadioField.Option
                id={PaymentMethod.CREDITCARD}
                svg={CreditCardIcon}
                title="mit Kreditkarte"
                onChange={(id) => setPaymentMethod(id as PaymentMethod)}
              />
              <IconRadioField.Option
                id={PaymentMethod.GIFTCARD}
                svg={GiftCardIcon}
                disabled
                title="mit Gutschein"
                onChange={(id) => setPaymentMethod(id as PaymentMethod)}
              />
              <IconRadioField.Option
                id={PaymentMethod.BITCOIN}
                svg={BitcoinIcon}
                disabled
                title="mit Bitcoin"
                onChange={(id) => setPaymentMethod(id as PaymentMethod)}
              />
            </IconRadioField>
            {paymentMethod === PaymentMethod.CREDITCARD ? (
              <CreditCard
                setCreditCardNumberRef={setCreditCardNumberRef}
                setCreditCardExpiryRef={setCreditCardExpiryRef}
                setCreditCardCvcRef={setCreditCardCvcRef}
              />
            ) : paymentMethod === PaymentMethod.CASH ? (
              <Cash />
            ) : paymentMethod === PaymentMethod.GIFTCARD ? (
              <GiftCard />
            ) : (
              <Bitcoin />
            )}
          </Container.Inner>
          <br />
          <CallToAction />
        </animated.form>
      )}
    />
  );
};

export default Payment;
