import React from 'react';
import { Link } from 'gatsby';
import Icon from '@soosap/sushi/Icon';

import ArrowLeftIcon from 'icons/ArrowLeft';
import styles from './Title.module.scss';

interface Props {
  component?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  className?: string;
  backTo?: string;
}

const Title: React.FC<Props> = ({
  children,
  className,
  component: Component = 'h3',
  backTo,
}) => {
  return (
    <Component className={`${styles[`Title`]} ${className ? className : ''}`}>
      {backTo && (
        <Link className={`${styles[`Title__back`]}`} to={backTo}>
          <Icon svg={ArrowLeftIcon} />
        </Link>
      )}
      {children}
    </Component>
  );
};

export default Title;
