import { atom } from 'recoil';

const isClient = typeof window !== 'undefined';

export default atom<string[]>({
  key: 'orderIds',
  default: [],
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      onSet((param) => {
        if (isClient) {
          localStorage.setItem('orderIds', (param as string[]).join(','));
        }
      });
      setSelf((param) => {
        return isClient
          ? ((localStorage.getItem('orderIds')?.split(',') ??
              param) as string[])
              .sort()
              .reverse()
          : param;
      });
    },
  ],
});
