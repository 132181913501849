import React from 'react';

import styles from '../../Summary.module.scss';

export interface Props {
  total: number;
  hasIntermediateSteps: boolean;
}

const TotalAmount: React.FC<Props> = ({ total, hasIntermediateSteps }) => {
  return (
    <>
      <div
        className={`${styles[`Summary__cell`]} ${
          hasIntermediateSteps
            ? styles[`Summary__cell--total`]
            : styles[`Summary__cell--subtotal`]
        } ${styles[`Summary__cell--1`]}`}
      >
        Gesamtsumme
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          hasIntermediateSteps
            ? styles[`Summary__cell--total`]
            : styles[`Summary__cell--subtotal`]
        } ${styles[`Summary__cell--2`]}`}
      >
        {`${(total / 100).toFixed(2).replace('.', ',')} EUR`}
      </div>
    </>
  );
};

export default TotalAmount;
