import { SelectOption } from '@soosap/sushi/Form/SelectField';
import moment from 'moment';

interface Props {
  start: moment.Moment;
  end: moment.Moment;
  interval?: number;
  cushion?: number; // timeintervals to exclude that lie between now and 30 minutes
  labelPrefix?: string;
  labelSuffix?: string;
  includeClosingTimeIfItIsStillComingUpAndNoOtherTimeInvervalIsInResultSet?: boolean;
}

function calculateTimeSlots({
  start,
  end,
  interval = 15,
  cushion = 30,
  labelPrefix = 'um ',
  labelSuffix = ' Uhr',
  includeClosingTimeIfItIsStillComingUpAndNoOtherTimeInvervalIsInResultSet = false,
}: Props): SelectOption[] {
  // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
  // note that 59 will round up to 60, and moment.js handles that correctly
  start.minutes(Math.ceil(start.minutes() / interval) * interval);

  var result = [];
  var current = moment(start).seconds(0);

  if (moment().isBefore(end)) {
    const remainder = 5 - (moment().minute() % 5);
    const earliest = moment(moment().isAfter(start) ? moment() : moment(start))
      .seconds(0)
      .add(cushion, 'minute')
      .add(moment().isAfter(start) && remainder < 5 ? remainder : 0, 'minute');

    if (earliest.isBefore(end)) {
      result.push({
        label: `${labelPrefix}${earliest.format(
          moment.HTML5_FMT.TIME
        )}${labelSuffix}`,
        value: earliest.toDate(),
      });
    }
  }

  while (current <= end) {
    const afterTime = moment().isAfter(start) ? moment() : moment(start);
    if (current.isAfter(afterTime.add(cushion + interval - 1, 'minute'))) {
      result.push({
        label: `${labelPrefix}${current.format(
          moment.HTML5_FMT.TIME
        )}${labelSuffix}`,
        value: current.toDate(),
      });
    }

    current.add(interval, 'minutes');
  }

  if (
    includeClosingTimeIfItIsStillComingUpAndNoOtherTimeInvervalIsInResultSet
  ) {
    if (result.length === 0 && moment().isBefore(end)) {
      result.push({
        label: `${labelPrefix}${end.format(
          moment.HTML5_FMT.TIME
        )}${labelSuffix} (Ende Öffnungszeit)`,
        value: end.toDate(),
      });
    }
  }

  return result;
}

export default calculateTimeSlots;
