import { atomFamily } from 'recoil';

export const initialMapCenter = { lat: 49.119536, lng: 9.188029 };

export default atomFamily<
  google.maps.LatLngLiteral | google.maps.LatLng,
  string
>({
  key: 'mapCenter',
  default: () => initialMapCenter,
  dangerouslyAllowMutability: true,
});
