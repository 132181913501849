// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-bestellen-tsx": () => import("./../../../src/pages/bestellen.tsx" /* webpackChunkName: "component---src-pages-bestellen-tsx" */),
  "component---src-pages-catering-service-tsx": () => import("./../../../src/pages/catering-service.tsx" /* webpackChunkName: "component---src-pages-catering-service-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-getraenkekarte-tsx": () => import("./../../../src/pages/getraenkekarte.tsx" /* webpackChunkName: "component---src-pages-getraenkekarte-tsx" */),
  "component---src-pages-gutschein-verschenken-tsx": () => import("./../../../src/pages/gutschein-verschenken.tsx" /* webpackChunkName: "component---src-pages-gutschein-verschenken-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-aufnehmen-tsx": () => import("./../../../src/pages/kontakt-aufnehmen.tsx" /* webpackChunkName: "component---src-pages-kontakt-aufnehmen-tsx" */),
  "component---src-pages-speisekarte-tamilische-spezialitaeten-tsx": () => import("./../../../src/pages/speisekarte-tamilische-spezialitaeten.tsx" /* webpackChunkName: "component---src-pages-speisekarte-tamilische-spezialitaeten-tsx" */),
  "component---src-pages-tisch-reservieren-tsx": () => import("./../../../src/pages/tisch-reservieren.tsx" /* webpackChunkName: "component---src-pages-tisch-reservieren-tsx" */),
  "component---src-pages-ueber-uns-index-tsx": () => import("./../../../src/pages/ueber-uns/index.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-index-tsx" */),
  "component---src-pages-ueber-uns-thilagam-tsx": () => import("./../../../src/pages/ueber-uns/thilagam.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-thilagam-tsx" */),
  "component---src-pages-weine-aus-heilbronn-und-indien-tsx": () => import("./../../../src/pages/weine-aus-heilbronn-und-indien.tsx" /* webpackChunkName: "component---src-pages-weine-aus-heilbronn-und-indien-tsx" */)
}

