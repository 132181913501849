import ChiliIcon from 'icons/Chili';
import VegetarianIcon from 'icons/Vegetarian';
import VeganIcon from 'icons/Vegan';

interface Icons {
  [iconName: string]: React.FC<React.SVGProps<SVGSVGElement>>;
}

const icons: Icons = {
  Chili: ChiliIcon,
  Vegetarian: VegetarianIcon,
  Vegan: VeganIcon,
};

export default icons;
