import React from 'react';

import styles from '../../Summary.module.scss';

export interface Props {}

const TableHeading: React.FC<Props> = () => {
  return (
    <>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--header`]
        } ${styles[`Summary__cell--1`]}`}
      >
        Position
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--header`]
        } ${styles[`Summary__cell--2`]}`}
      >
        Menge
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--header`]
        } ${styles[`Summary__cell--3`]}`}
      >
        Preis
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--header`]
        } ${styles[`Summary__cell--4`]}`}
      >
        Summe
      </div>
    </>
  );
};

export default TableHeading;
