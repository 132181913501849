import React, { useState, useEffect, useCallback } from 'react';
import Button, { ButtonTheme } from '@soosap/sushi/Button';
import GoogleMapsIcon from '@soosap/sushi/icons/GoogleMaps';

import Collapse from '../../../Collapse';
import { HandoverMethod, Order } from 'server/src/app/order/types';
import styles from '../../../CallToAction/CallToAction.module.scss';
import moment from 'moment';

const STRETCH_TRESHOLD = 100;

export interface Props {
  className?: string;
  order: Order;
}

const CallToAction: React.FC<Props> = ({ className, order }) => {
  const [shouldStretchHeight, setShouldStretchHeight] = useState<boolean>(
    false
  );

  const handleTouch = useCallback(() => {
    setShouldStretchHeight(
      window.screen.height - window.innerHeight < STRETCH_TRESHOLD
    );
  }, []);

  useEffect(() => {
    window.addEventListener('touchstart', handleTouch);
    return () => window.addEventListener('touchstart', handleTouch);
  }, []);

  useEffect(() => {
    window.addEventListener('touchmove', handleTouch);
    return () => window.addEventListener('touchmove', handleTouch);
  }, []);

  return (
    <Button.Group
      className={`${styles[`CallToAction`]} ${
        shouldStretchHeight ? styles[`CallToAction--stretched`] : ''
      } ${className ? className : ''}`}
    >
      {order.handoverMethod === HandoverMethod.PICKUP ? (
        <Button
          theme={ButtonTheme.DARK}
          borderless
          className={`${styles[`CallToAction__button`]} ${
            styles[`CallToAction__button--forward`]
          }`}
          classNameInner={styles[`CallToAction__button-inner`]}
          onClick={() => {
            window.open(
              'https://www.google.com/maps/dir/?api=1&destination=THAMARAI+Restaurant+Heilbronn,+Horkheimer+Str.+29,+74081+Heilbronn,+Deutschland&dir_action=navigate'
            );
          }}
        >
          <div>
            Abholung um {moment(order.handoverAt).format('HH:mm [Uhr]')}
          </div>
          <div>🚘&nbsp;&nbsp;Routenführung starten</div>
        </Button>
      ) : (
        <Button
          theme={ButtonTheme.DARK}
          borderless
          className={`${styles[`CallToAction__button`]} ${
            styles[`CallToAction__button--forward`]
          }`}
          classNameInner={styles[`CallToAction__button-inner`]}
          onClick={() => {
            window.open(
              'http://search.google.com/local/writereview?placeid=ChIJMVXt6ucpmEcRGjZLckbiBWg'
            );
          }}
        >
          <div>
            Lieferung um ca. {moment(order.handoverAt).format('HH:mm [Uhr]')}
          </div>
          <div>✍️&nbsp;&nbsp;Bewertung schreiben</div>
        </Button>
      )}
      <Collapse />
    </Button.Group>
  );
};

export default CallToAction;
