import React from 'react';
import { useRecoilValue } from 'recoil';
import { useSpring, animated } from 'react-spring';
import {
  Basket,
  BasketStatus,
  HandoverMethod,
} from 'server/src/app/order/types';
import icons from 'icons/index';
import offersState from 'state/offers';
import foodState from 'state/food';
import { prevBasketStatusState } from 'state/basketStatus';
import basketOpenState from 'state/basketOpen';

import IndividualPosition from './components/IndividualPosition';
import DeliveryCost from './components/DeliveryCost';
import TotalAmount from './components/TotalAmount';
import TableHeading from './components/TableHeading';
import SubtotalAmount from './components/SubtotalAmount';
import styles from './Summary.module.scss';
import CallToAction from '../CallToAction';

const DELIVERY_COST = Number(process.env.GATSBY_DELIVERY_COST);

export interface Props {
  basket: Basket;
  className?: string;
  freeDeliveryApplies: boolean;
  handoverMethod: HandoverMethod;
  total: number;
}

const Summary: React.FC<Props> = ({
  basket,
  className,
  freeDeliveryApplies,
  handoverMethod,
  total,
}) => {
  const offers = useRecoilValue(offersState);
  const food = useRecoilValue(foodState);
  const prevBasketStatus = useRecoilValue(prevBasketStatusState);
  const basketOpen = useRecoilValue(basketOpenState);

  const containerStyles = useSpring({
    from: {
      maxHeight: '100vh',
    },
    to: {
      maxHeight: basketOpen ? '100vh' : '0vh',
    },
    config: {
      friction: 40,
      tension: 300,
    },
  });

  const gridStyles = useSpring({
    from: {
      left: prevBasketStatus === BasketStatus.SELECTION ? '0' : '-10%',
      opacity: prevBasketStatus === BasketStatus.SELECTION ? 1 : 0,
    },
    to: {
      left: '0',
      opacity: basketOpen ? 1 : 0,
    },
  });

  return (
    <>
      <animated.div
        style={containerStyles}
        className={`${styles[`Summary`]} ${className ? className : ''}`}
      >
        <animated.div className={styles[`Summary__grid`]} style={gridStyles}>
          <TableHeading />
          {Object.values(basket)
            .sort((a, b) => {
              if (!a.includedAt || !b.includedAt) return 1;
              return a.includedAt > b.includedAt ? 1 : -1;
            })
            .map((position) => {
              const foodItem = food[position.productId];

              return (
                <IndividualPosition
                  key={position.id}
                  foodItem={foodItem}
                  position={position}
                  offers={offers}
                  icons={icons}
                />
              );
            })}
          {handoverMethod === HandoverMethod.DELIVERY && (
            <SubtotalAmount
              total={total}
              freeDeliveryApplies={freeDeliveryApplies}
              deliveryCost={DELIVERY_COST}
            />
          )}
          {handoverMethod === HandoverMethod.DELIVERY && (
            <DeliveryCost
              deliveryCost={DELIVERY_COST}
              freeDeliveryApplies={freeDeliveryApplies}
            />
          )}
          <TotalAmount
            total={total}
            hasIntermediateSteps={handoverMethod === HandoverMethod.DELIVERY}
          />
        </animated.div>
      </animated.div>
      <CallToAction />
    </>
  );
};

export default Summary;
