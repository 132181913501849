import React, { useEffect, useState } from 'react';
import { useRecoilValue, useRecoilCallback } from 'recoil';
import { useFormState, useForm } from 'react-final-form';
import Typist from 'react-typist';
import moment from 'moment';
import Icon, { IconSize } from '@soosap/sushi/Icon';
import ProgressBar from '@soosap/sushi/ProgressBar';
import AddressField from '@soosap/sushi/Form/AddressField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';
import FreeShippingIcon from '@soosap/sushi/icons/FreeShipping';

import Container from 'atoms/Container';
import deliveryTimeSlotsState from 'state/deliveryTimeSlots';
import subtotalState from 'state/subtotal';
import deliveryHours from 'state/deliveryHours';

import calculateTimeSlots from '../../utils/calculateTimeSlots';
import styles from './Delivery.module.scss';

const DELIVERY_CUSHION = Number(process.env.GATSBY_DELIVERY_CUSHION);
const DELIVERY_FREE_SHIPPING = Number(
  process.env.GATSBY_DELIVERY_FREE_SHIPPING
);
const DELIVERY_COST = Number(process.env.GATSBY_DELIVERY_COST);
const DELIVERY_RADIUS = Number(process.env.GATSBY_DELIVERY_RADIUS);
const DELIVERY_RADIUS_DISPLAY = Math.floor(DELIVERY_RADIUS / 1000);

moment.relativeTimeThreshold('m', 60);

export interface Props {}

const Delivery: React.FC<Props> = () => {
  const timeSlots = useRecoilValue(deliveryTimeSlotsState);
  const subtotal = useRecoilValue(subtotalState);
  const hours = useRecoilValue(deliveryHours);
  const formState = useFormState({ subscription: { values: true } });
  const form = useForm();

  if (!window.google) return null;

  const refreshTimeSlots = useRecoilCallback(({ set }) => () => {
    const day = moment().day();
    const openingHours = Object.values(hours)[day === 0 ? 6 : day - 1];

    let timeSlots: SelectOption[] = [];

    if (openingHours.lunchActive) {
      const start = moment(openingHours.lunchFrom, moment.HTML5_FMT.TIME);
      const end = moment(openingHours.lunchTo, moment.HTML5_FMT.TIME);
      timeSlots = [
        ...timeSlots,
        ...calculateTimeSlots({
          start,
          end,
          interval: 15,
          cushion: DELIVERY_CUSHION,
          labelPrefix: 'um ca. ',
        }),
      ];
    }

    if (openingHours.dinnerActive) {
      const start = moment(openingHours.dinnerFrom, 'HH:mm');
      const end = moment(openingHours.dinnerTo, 'HH:mm');
      timeSlots = [
        ...timeSlots,
        ...calculateTimeSlots({
          start,
          end,
          interval: 15,
          cushion: DELIVERY_CUSHION,
          labelPrefix: 'um ca. ',
        }),
      ];
    }

    set(deliveryTimeSlotsState, timeSlots);

    // Update initial value
    if (
      formState.values[`handoverAt`] &&
      timeSlots.length > 0 &&
      moment(formState.values[`handoverAt`]).isBefore(
        moment(timeSlots[0].value as Date)
      )
    ) {
      const updatedHandoverAt = moment(formState.values[`handoverAt`]).isAfter(
        moment().add(DELIVERY_CUSHION, 'minutes')
      )
        ? formState.values[`handoverAt`]
        : timeSlots[0].value;

      form.reset({
        ...formState.values,
        handoverAt: updatedHandoverAt,
      });
    }
  });

  // refresh timeslots every 60 sec
  useEffect(() => {
    const intervalID = setInterval(refreshTimeSlots, 60000);
    return () => clearInterval(intervalID);
  }, [refreshTimeSlots]);

  // retrieve timeslots at initial render
  useEffect(refreshTimeSlots, []);

  const [typingFinished, setTypingFinished] = useState<
    'reached' | 'not-reached' | false
  >(false);

  useEffect(() => {
    if (subtotal >= DELIVERY_FREE_SHIPPING && typingFinished !== 'reached') {
      setTypingFinished(false);
    } else if (
      subtotal < DELIVERY_FREE_SHIPPING &&
      typingFinished === 'reached'
    ) {
      setTypingFinished(false);
    }
  }, [subtotal]);

  return (
    <Container.Inner className={styles[`Delivery`]}>
      <ProgressBar
        label={() => (
          <div className={styles[`Delivery__free-shipping`]}>
            <Icon
              className={styles[`Delivery__free-shipping-icon`]}
              svg={FreeShippingIcon}
              primary
              size={IconSize.LARGE}
            />
            {typingFinished ? (
              subtotal <= DELIVERY_FREE_SHIPPING ? (
                <span>
                  {`Kostenfreie Lieferung ab ${
                    DELIVERY_FREE_SHIPPING / 100
                  } EUR`}
                </span>
              ) : (
                <span>Kostenlose Lieferung aktiviert</span>
              )
            ) : (
              <Typist
                cursor={{ hideWhenDone: true, hideWhenDoneDelay: 100 }}
                onTypingDone={() =>
                  setTypingFinished(
                    subtotal >= DELIVERY_FREE_SHIPPING
                      ? 'reached'
                      : 'not-reached'
                  )
                }
              >
                {subtotal <= DELIVERY_FREE_SHIPPING ? (
                  <span>
                    <span>
                      {`Lieferkosten ${(DELIVERY_COST / 100)
                        .toFixed(2)
                        .replace('.', ',')} EUR`}
                    </span>
                    <Typist.Backspace count={31} delay={2000} />
                    <span>
                      {`Kostenfreie Lieferung ab ${
                        DELIVERY_FREE_SHIPPING / 100
                      } EUR`}
                    </span>
                  </span>
                ) : (
                  <span>Kostenlose Lieferung aktiviert</span>
                )}
              </Typist>
            )}
          </div>
        )}
        denominator={subtotal / 100}
        denominatorDisplay={(d) => `${d.toFixed(2).replace('.', ',')}€`}
        className={styles[`Delivery__free-shipping-progress`]}
        classNameDenominator={styles[`Delivery__free-shipping-denominator`]}
        divisor={DELIVERY_FREE_SHIPPING / 100}
      />
      {timeSlots.length > 0 ? (
        <SelectField
          name="handoverAt"
          label="Lieferzeit"
          initialValue={timeSlots[0].value}
          options={timeSlots}
          note={() => (
            <>
              Die {timeSlots.length > 1 ? 'frühestmögliche' : 'letzte'}{' '}
              Lieferung ist{' '}
              <span className={styles[`Delivery__highlight`]}>
                {timeSlots[0].label}
              </span>{' '}
              möglich
            </>
          )}
          required
          requiredError="Pflichtfeld"
          placeholder="Wann sollen wir vorbeikommen?"
        />
      ) : (
        <SelectField
          name="handoverAt"
          label="Lieferzeit"
          options={timeSlots}
          // note="Es ist keine Lieferung mehr möglich."
          disabled
          required
          requiredError="Pflichtfeld"
          placeholder="Es ist keine Lieferung mehr möglich"
        />
      )}

      <AddressField
        name="deliveryAddress"
        label="Lieferadresse"
        location={{ lat: 49.1195041, lng: 9.1858457 }}
        placeholder="z.B. Horkheimer Straße 29, 74081 Heilbronn"
        outOfRadiusError={`Sorry, wir liefern nur im Umkreis von ${DELIVERY_RADIUS_DISPLAY} km`}
        outOfRadiusSuccess="Ihre Adresse liegt in unserer Lieferzone"
        radius={DELIVERY_RADIUS}
        required
        requiredError="Pflichtfeld"
        routeError="Bitte geben Sie auch Ihre Hausnummer an"
        note={() => (
          <>
            Wir liefern im Umkreis von{' '}
            <span className={styles[`Delivery__highlight`]}>
              {DELIVERY_RADIUS_DISPLAY} km
            </span>
          </>
        )}
      />
      <TextAreaField
        name="message"
        className={styles[`Delivery__message`]}
        label="Mitteilung (optional)"
        placeholder="Haben Sie noch etwas auf dem Herzen? Lassen Sie es uns wissen!"
      />
      <br />
    </Container.Inner>
  );
};

export default Delivery;
