import React from 'react';

import styles from '../../Summary.module.scss';

export interface Props {
  total: number;
  freeDeliveryApplies: boolean;
  deliveryCost: number;
}

const SubtotalAmount: React.FC<Props> = ({
  total,
  freeDeliveryApplies,
  deliveryCost,
}) => {
  return (
    <>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--subtotal`]
        } ${styles[`Summary__cell--1`]}`}
      >
        Zwischensumme
      </div>
      <div
        className={`${styles[`Summary__cell`]} ${
          styles[`Summary__cell--subtotal`]
        } ${styles[`Summary__cell--2`]}`}
      >
        {`${((total - (freeDeliveryApplies ? 0 : deliveryCost)) / 100)
          .toFixed(2)
          .replace('.', ',')} EUR`}
      </div>
    </>
  );
};

export default SubtotalAmount;
