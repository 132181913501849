import { useMemo } from 'react';
import { BusinessHours, BusinessHour } from 'server/src/app/opening/types';

export type FormattedHours = { key: string; val: string[] }[];

const useFormattedHours = (
  hours: BusinessHours,
  closedLabel: string = 'geschlossen'
): FormattedHours => {
  const formattedHours = useMemo(() => {
    return Object.values(hours)
      .sort((a, b) => a.position - b.position)
      .reduce((prev: { key: string; val: string[] }[], curr: BusinessHour) => {
        const {
          abbrev,
          lunchActive,
          lunchFrom,
          lunchTo,
          dinnerActive,
          dinnerFrom,
          dinnerTo,
        } = curr;
        const closed = !lunchActive && !dinnerActive;
        const lunchOnly = lunchActive && !dinnerActive;
        const dinnerOnly = !lunchActive && dinnerActive;

        if (!prev.length) {
          // if prev is not yet populated
          let val: string[] = [];
          if (lunchActive) val.push(`${lunchFrom}-${lunchTo}`);
          if (dinnerActive) val.push(`${dinnerFrom}-${dinnerTo}`);

          const key = abbrev;
          val = closed ? [closedLabel] : val;

          prev.push({ key, val });
        } else {
          if (closed) {
            // handle the case where current day is closed
            // find last element in array and check if it is closed
            if (prev[prev.length - 1].val.includes(closedLabel)) {
              // if so append abbrev to key
              prev[prev.length - 1].key = `${
                prev[prev.length - 1].key.split('-')[0]
              }-${abbrev}`;
            } else {
              // else create new entry in array
              prev.push({ key: abbrev, val: [closedLabel] });
            }
          } else if (lunchOnly) {
            if (
              prev[prev.length - 1].val.includes(`${lunchFrom}-${lunchTo}`) &&
              prev[prev.length - 1].val.length === 1
            ) {
              // if so append abbrev to key
              prev[prev.length - 1].key = `${
                prev[prev.length - 1].key.split('-')[0]
              }-${abbrev}`;
            } else {
              prev.push({
                key: abbrev,
                val: [`${lunchFrom}-${lunchTo}`],
              });
            }
          } else if (dinnerOnly) {
            if (
              prev[prev.length - 1].val.includes(`${dinnerFrom}-${dinnerTo}`) &&
              prev[prev.length - 1].val.length === 1
            ) {
              // if so append abbrev to key
              prev[prev.length - 1].key = `${
                prev[prev.length - 1].key.split('-')[0]
              }-${abbrev}`;
            } else {
              prev.push({
                key: abbrev,
                val: [`${dinnerFrom}-${dinnerTo}`],
              });
            }
          } else {
            // handle the case where current day is opened
            // find last element in array and check if it has same opening times as the current day
            if (
              prev[prev.length - 1].val.includes(`${lunchFrom}-${lunchTo}`) ||
              prev[prev.length - 1].val.includes(`${dinnerFrom}-${dinnerTo}`)
            ) {
              // if so append abbrev to key
              prev[prev.length - 1].key = `${
                prev[prev.length - 1].key.split('-')[0]
              }-${abbrev}`;
            } else {
              // else create new entry in array
              prev.push({
                key: abbrev,
                val: [`${lunchFrom}-${lunchTo}`, `${dinnerFrom}-${dinnerTo}`],
              });
            }
          }
        }

        return prev;
      }, []);
  }, [hours]);

  return formattedHours;
};

export default useFormattedHours;
