import React from 'react';
import { Marker } from '@react-google-maps/api';
import { useRecoilValue } from 'recoil';

import directionsState from 'state/directions';
import useFinalDestinationQuery from './useFinalDestinationQuery';

const MARKER_WIDTH = 30;

export interface Props {
  orderId: string;
}

const FinalDestination: React.FC<Props> = ({ orderId }) => {
  const { finalDestination } = useFinalDestinationQuery();
  const directions = useRecoilValue(directionsState(orderId));

  if (!directions?.routes[0]?.legs[0]?.end_location) {
    return null;
  }

  if (!finalDestination?.childImageSharp?.fluid) {
    throw new Error('FinalDestination image is required');
  }

  return (
    <Marker
      position={directions.routes[0].legs[0].end_location}
      icon={{
        url: finalDestination.childImageSharp.fluid.src,
        scaledSize: new window.google.maps.Size(
          MARKER_WIDTH * finalDestination.childImageSharp.fluid.aspectRatio,
          MARKER_WIDTH
        ),
        anchor: new window.google.maps.Point(
          0 + 2,
          MARKER_WIDTH * finalDestination.childImageSharp.fluid.aspectRatio - 2
        ),
      }}
    />
  );
};

export default FinalDestination;
