import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { animated, useSpring, config } from 'react-spring';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useLoadScript } from '@react-google-maps/api';
import { Libraries } from '@react-google-maps/api/dist/utils/make-load-script-url';
import Tabs from '@soosap/sushi/Tabs';
import Icon, { IconSize } from '@soosap/sushi/Icon';

import ShoppingBasketIcon from 'icons/ShoppingBasket';
import basketDefaultTabIndexState from 'state/basketDefaultTabIndex';
import orderIdsState from 'state/orderIds';
import totalState from 'state/total';
import deliveryHoursState from 'state/deliveryHours';
import pickupHoursState from 'state/pickupHours';
import basketOpenState from 'state/basketOpen';

import Checkout from './components/Checkout';
import Confirmation from './components/Confirmation';
import useBasketQuery from './useBasketQuery';
import styles from './Basket.module.scss';

const GOOGLE_MAPS_LIBRARIES: Libraries = ['places', 'geometry', 'drawing'];
const GOOGLE_MAPS_API_KEY = `${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`;
const LEFT_START = 14;
const TAB_WIDTH = 75;

export interface Props {}

const Basket: React.FC<Props> = () => {
  const setDeliveryHours = useSetRecoilState(deliveryHoursState);
  const setPickupHours = useSetRecoilState(pickupHoursState);
  const { deliveryHours, pickupHours } = useBasketQuery();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const total = useRecoilValue(totalState);
  const orderIds = useRecoilValue(orderIdsState);
  const basketDefaultTabIndex = useRecoilValue(basketDefaultTabIndexState);
  const setBasketOpen = useSetRecoilState(basketOpenState);

  deliveryHours && setDeliveryHours(deliveryHours);
  pickupHours && setPickupHours(pickupHours);

  useEffect(() => {
    setStripePromise(
      loadStripe(String(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY))
    );
  }, []);

  const basketStyles = useSpring({
    from: { bottom: '-400px', opacity: 0 },
    to: { bottom: '0', opacity: 1 },
    config: { ...config.wobbly, tension: 220 },
  });

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAPS_LIBRARIES,
  });

  if (loadError || !isLoaded) return null;

  const recentOrderIds = orderIds
    .filter((orderItem) => {
      const [orderId, orderedAt] = orderItem.split(':');

      return moment()
        .subtract(1, 'day')
        .isBefore(moment(parseInt(orderedAt)));
    })
    .map((orderItem) => {
      const [orderId] = orderItem.split(':');

      return orderId;
    });

  return total > 0 || recentOrderIds.length > 0 ? (
    <animated.div className={styles[`Basket`]} style={basketStyles}>
      <Elements stripe={stripePromise || null}>
        <Tabs
          defaultIndex={basketDefaultTabIndex}
          className={styles[`Basket__tabs`]}
          panelClassName={styles[`Basket__panel`]}
          selectedTabClassName={styles[`Basket__tab--selected`]}
          tabClassName={styles[`Basket__tab`]}
          tabListClassName={styles[`Basket__tab-list`]}
          onSelect={() => setBasketOpen(true)}
        >
          {total > 0 && (
            <Tabs.Tab
              title={<Icon svg={ShoppingBasketIcon} size={IconSize.MEDIUM} />}
              tabClassName={`${styles[`Basket__tab`]} ${
                styles[`Basket__tab--basket`]
              }`}
              tabStyle={{ left: LEFT_START }}
            >
              <Checkout />
            </Tabs.Tab>
          )}
          {recentOrderIds.map((orderId, idx) => {
            const left =
              (total > 0 ? LEFT_START + TAB_WIDTH : LEFT_START) +
              idx * TAB_WIDTH;

            return (
              <Tabs.Tab
                key={orderId}
                title={`#${orderId}`}
                tabClassName={`${styles[`Basket__tab`]} ${
                  styles[`Basket__tab--order`]
                }`}
                tabStyle={{ left }}
              >
                <Confirmation orderId={orderId} />
              </Tabs.Tab>
            );
          })}
        </Tabs>
      </Elements>
    </animated.div>
  ) : null;
};

export default Basket;
