import { selector } from 'recoil';

import subtotalAfterGiftCardState from 'state/subtotalAfterGiftCard';

export default selector({
  key: 'total',
  get: ({ get }) => {
    const subtotalAfterGiftCard = get(subtotalAfterGiftCardState);

    return subtotalAfterGiftCard;
  },
});
