import React from 'react';
import { RecoilRoot } from 'recoil';

import { ToastProvider } from '@soosap/sushi/Toast';
import { StatusProvider } from '@soosap/sushi/Status';
import Basket from 'organisms/Basket';

export const wrapRootElement = ({ element }) => (
  <RecoilRoot>
    <StatusProvider>
      <ToastProvider>
        {element}
        <Basket />
      </ToastProvider>
    </StatusProvider>
  </RecoilRoot>
);
