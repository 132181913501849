import React from 'react';
import { useRecoilValue } from 'recoil';

import { BasketStatus } from 'server/src/app/order/types';
import totalState from 'state/total';
import basketState from 'state/basket';
import basketStatusState from 'state/basketStatus';
import handoverMethodState from 'state/handoverMethod';
import freeDeliveryAppliesState from 'state/freeDeliveryApplies';

import Handover from '../Handover';
import Payment from '../Payment';
import Summary from '../Summary';
import styles from './Checkout.module.scss';

export interface Props {}

const Checkout: React.FC<Props> = () => {
  const basket = useRecoilValue(basketState);
  const basketStatus = useRecoilValue(basketStatusState);
  const handoverMethod = useRecoilValue(handoverMethodState);
  const freeDeliveryApplies = useRecoilValue(freeDeliveryAppliesState);
  const total = useRecoilValue(totalState);

  return (
    <div className={styles[`Checkout`]}>
      {basketStatus === BasketStatus.SELECTION ? (
        <div className={styles[`Checkout__inner`]}>
          <Summary
            className={styles[`Basket__summary`]}
            basket={basket}
            handoverMethod={handoverMethod}
            freeDeliveryApplies={freeDeliveryApplies}
            total={total}
          />
        </div>
      ) : basketStatus === BasketStatus.HANDOVER ? (
        <div className={styles[`Checkout`]}>
          <Handover />
        </div>
      ) : basketStatus === BasketStatus.PAYMENT ? (
        <div className={styles[`Checkout`]}>
          <Payment />
        </div>
      ) : null}
    </div>
  );
};

export default Checkout;
