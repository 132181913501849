import { graphql, useStaticQuery } from 'gatsby';

export default () =>
  useStaticQuery<GatsbyTypes.FinalDestinationQuery>(graphql`
    query FinalDestination {
      finalDestination: file(relativePath: { eq: "delivery/finish-flag.png" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
