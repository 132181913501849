import React from 'react';
import { Marker } from '@react-google-maps/api';

import useHeadquarterQuery from './useHeadquarterQuery';

const MARKER_WIDTH = 75;

export interface Props {
  position: google.maps.LatLng | google.maps.LatLngLiteral;
}

const Headquarter: React.FC<Props> = ({ position }) => {
  const { headquarter } = useHeadquarterQuery();

  if (!headquarter?.childImageSharp?.fluid) {
    throw new Error('Headquarter image is required');
  }

  return (
    <Marker
      position={position}
      icon={{
        url: headquarter.childImageSharp.fluid.src,
        scaledSize: new window.google.maps.Size(
          MARKER_WIDTH * headquarter.childImageSharp.fluid.aspectRatio,
          MARKER_WIDTH
        ),
        anchor: new window.google.maps.Point(
          (MARKER_WIDTH * headquarter.childImageSharp.fluid.aspectRatio) / 2,
          MARKER_WIDTH / 2
        ),
      }}
    />
  );
};

export default Headquarter;
