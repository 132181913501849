import React, { useEffect, useState } from 'react';
import {
  useSetRecoilState,
  useRecoilState,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';
import { useFormState, useForm } from 'react-final-form';
import moment from 'moment';
import 'moment/locale/de';
import SelectField, { SelectOption } from '@soosap/sushi/Form/SelectField';
import TextAreaField from '@soosap/sushi/Form/TextAreaField';

import Container from 'atoms/Container';
import pickupTimeSlotsState from 'state/pickupTimeSlots';
import pickupHours from 'state/pickupHours';

import calculateTimeSlots from '../../utils/calculateTimeSlots';
import styles from './Pickup.module.scss';

const PICKUP_CUSHION = Number(process.env.GATSBY_PICKUP_CUSHION);

export interface Props {}

const Pickup: React.FC<Props> = () => {
  const timeSlots = useRecoilValue(pickupTimeSlotsState);
  const hours = useRecoilValue(pickupHours);
  const formState = useFormState({ subscription: { values: true } });
  const form = useForm();
  const refreshTimeSlots = useRecoilCallback(({ set }) => () => {
    const day = moment().day();
    const openingHours = Object.values(hours)[day === 0 ? 6 : day - 1];

    let timeSlots: SelectOption[] = [];

    if (openingHours.lunchActive) {
      const start = moment(openingHours.lunchFrom, moment.HTML5_FMT.TIME);
      const end = moment(openingHours.lunchTo, moment.HTML5_FMT.TIME);
      timeSlots = [
        ...timeSlots,
        ...calculateTimeSlots({
          start,
          end,
          cushion: PICKUP_CUSHION,
          includeClosingTimeIfItIsStillComingUpAndNoOtherTimeInvervalIsInResultSet: true,
        }),
      ];
    }

    if (openingHours.dinnerActive) {
      const start = moment(openingHours.dinnerFrom, moment.HTML5_FMT.TIME);
      const end = moment(openingHours.dinnerTo, moment.HTML5_FMT.TIME);
      timeSlots = [
        ...timeSlots,
        ...calculateTimeSlots({
          start,
          end,
          cushion: PICKUP_CUSHION,
          includeClosingTimeIfItIsStillComingUpAndNoOtherTimeInvervalIsInResultSet: true,
        }),
      ];
    }

    set(pickupTimeSlotsState, timeSlots);

    // Update initial value
    if (
      formState.values[`handoverAt`] &&
      timeSlots.length > 0 &&
      moment(formState.values[`handoverAt`]).isBefore(
        moment(timeSlots[0].value as Date)
      )
    ) {
      const updatedHandoverAt = moment(formState.values[`handoverAt`]).isAfter(
        moment().add(PICKUP_CUSHION, 'minutes')
      )
        ? formState.values[`handoverAt`]
        : timeSlots[0].value;

      form.reset({
        ...formState.values,
        handoverAt: updatedHandoverAt,
      });
    }
  });

  // refresh timeslots every 60 sec
  useEffect(() => {
    const intervalID = setInterval(refreshTimeSlots, 60000);
    return () => clearInterval(intervalID);
  }, [refreshTimeSlots]);

  // retrieve timeslots at initial render
  useEffect(refreshTimeSlots, []);

  return (
    <Container.Inner className={styles[`Pickup`]}>
      {timeSlots.length > 0 ? (
        <SelectField
          name="handoverAt"
          label="Abholzeit"
          initialValue={timeSlots[0].value}
          options={timeSlots}
          required
          requiredError="Pflichtfeld"
          placeholder="Wann möchten Sie vorbeikommen?"
          note={() => (
            <>
              Die {timeSlots.length > 1 ? 'frühestmögliche' : 'letzte'} Abholung
              ist{' '}
              <span className={styles[`Pickup__highlight`]}>
                {timeSlots[0].label}
              </span>{' '}
              möglich.
            </>
          )}
        />
      ) : (
        <SelectField
          name="handoverAt"
          label="Abholzeit"
          options={timeSlots}
          required
          requiredError="Pflichtfeld"
          placeholder="Es ist keine Abholung mehr möglich"
          disabled
          // note="Es ist keine Abholung mehr möglich"
          noOptionsMessage="Es ist keine Abholung mehr möglich"
        />
      )}
      <TextAreaField
        name="message"
        className={styles[`Pickup__message`]}
        // collapsible
        // initiallyCollapsed
        label="Mitteilung (optional)"
        placeholder="Haben Sie noch etwas auf dem Herzen? Lassen Sie es uns wissen!"
      />
      <br />
    </Container.Inner>
  );
};

export default Pickup;
