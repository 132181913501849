import React from 'react';

import Inner, { Props as InnerProps } from './components/Inner';
import styles from './Container.module.scss';

interface FC<P = {}> extends React.FC<P> {
  Inner: React.FC<InnerProps>;
}

export interface Props {
  className?: string;
}

const Container: FC<Props> = ({ children, className }) => {
  return (
    <div className={`${styles[`Container`]} ${className ? className : ''}`}>
      <div className={styles['Container__inner']}>{children}</div>
    </div>
  );
};

Container.Inner = Inner;

export default Container;
