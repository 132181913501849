import React from 'react';

import Container from 'atoms/Container';
import styles from './Bitcoin.module.scss';

export interface Props {}

const Bitcoin: React.FC<Props> = () => {
  return (
    <Container.Inner className={styles[`Bitcoin`]}>
      <span>Bitcoin</span>
    </Container.Inner>
  );
};

export default Bitcoin;
