import { Environment } from '../../types';

export enum ShippingMethod {
  MAIL = 'mail',
  EMAIL = 'email',
  PICKUP = 'pickup',
}

export enum PaymentMethod {
  CASH = 'cash',
  CREDITCARD = 'creditcard',
  BITCOIN = 'bitcoin',
  GIFTCARD = 'giftcard',
}

export interface GiftCard {
  code: string;
  env: Environment;
  nominalAmount: number;
  redeemedAmount: number;
  nominalReceived: boolean;
  paymentMethod: PaymentMethod;
  shippingMethod: ShippingMethod;
  recipientFullName?: string;
  recipientEmail?: string;
  recipientAddress?: string;
  recipientPostCode?: string;
  recipientCity?: string;
  giverFullName: string;
  giverPhoneNumber?: string;
  dedication?: string;
  stripePaymentMethodId?: string;
  orderedAt: number;
}

export interface GiftCards {
  [code: string]: GiftCard;
}
