import { graphql, useStaticQuery } from 'gatsby';

export default () =>
  useStaticQuery<GatsbyTypes.ConfirmationQuery>(graphql`
    query Confirmation {
      site {
        siteMetadata {
          lat
          lng
          primaryColor
          secondaryColor
        }
      }
    }
  `);
