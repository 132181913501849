import { selector } from 'recoil';
import { Variants } from 'server/src/app/carte/types';

import basketState from 'state/basket';
import offersState from 'state/offers';

export default selector<number>({
  key: 'subtotal',
  get: ({ get }) => {
    const basket = get(basketState);
    const offers = get(offersState);

    const subtotal = basket
      ? Object.values(basket).reduce((prev, position) => {
          const variants: Variants = position.variants;
          const positionAmount = Object.values(variants).reduce(
            (prev, curr) => {
              return prev + (curr.checked ? curr.delta : 0);
            },
            offers[position.productId].quotes['1-x'].price
          );

          return prev + position.quantity * positionAmount;
        }, 0)
      : 0;

    return subtotal;
  },
});
