import React from 'react';

import Icon from '@soosap/sushi/Icon';
import InfoIcon from 'icons/Info';
import styles from './Info.module.scss';

export interface Props {}

const Info: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles[`Info`]}>
      <Icon className={styles[`Info__icon`]} svg={InfoIcon} inline />
      {children}
    </div>
  );
};

export default Info;
