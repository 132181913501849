import { atom } from 'recoil';
import { BasketStatus } from 'server/src/app/order/types';

export const prevBasketStatusState = atom<BasketStatus>({
  key: 'prevBasketStatus',
  default: BasketStatus.SELECTION,
});

export default atom<BasketStatus>({
  key: 'basketStatus',
  default: BasketStatus.SELECTION,
});
