import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useRecoilCallback } from 'recoil';
import moment from 'moment';
import Icon, { IconSize } from '@soosap/sushi/Icon';
import Dialog from '@soosap/sushi/Dialog';
import ButtonField from '@soosap/sushi/Form/ButtonField';

import { HandoverMethod } from 'server/src/app/order/types';
import Container from 'atoms/Container';
import Info from 'atoms/Info';
import Title from 'atoms/Title';
import DeliveryManIcon from 'icons/DeliveryMan';
import PaperBagIcon from 'icons/PaperBag';
import handoverMethodState from 'state/handoverMethod';
import deliveryHoursState from 'state/deliveryHours';
import pickupHoursState from 'state/pickupHours';
import closingSoonDialogOpenState from 'state/closingSoonDialogOpen';
import closedNowDialogOpenState from 'state/closedNowDialogOpen';
import closingSoonDialogSeenState from 'state/closingSoonDialogSeen';
import closedNowDialogSeenState from 'state/closedNowDialogSeen';
import pickupNextOpeningState from 'state/pickupNextOpening';
import deliveryNextOpeningState from 'state/deliveryNextOpening';

import Pickup from '../Pickup';
import Delivery from '../Delivery';
import DeliveryTimes from '../DeliveryTimes';
import styles from './PickupOrDelivery.module.scss';
import PickupTimes from '../PickupTimes';

const DELIVERY_CUSHION = Number(process.env.GATSBY_DELIVERY_CUSHION);
const PICKUP_CUSHION = Number(process.env.GATSBY_PICKUP_CUSHION);

export interface Props {}

const PickupOrDelivery: React.FC<Props> = () => {
  const [closingSoonDialogOpen, setClosingSoonDialogOpen] = useRecoilState(
    closingSoonDialogOpenState
  );
  const [closedNowDialogOpen, setClosedNowDialogOpen] = useRecoilState(
    closedNowDialogOpenState
  );
  const closingSoonDialogSeen = useRecoilValue(closingSoonDialogSeenState);
  const closedNowDialogSeen = useRecoilValue(closedNowDialogSeenState);
  const [handoverMethod, setHandoverMethod] = useRecoilState(
    handoverMethodState
  );
  const deliveryHours = useRecoilValue(deliveryHoursState);
  const pickupHours = useRecoilValue(pickupHoursState);
  const pickupNextOpening = useRecoilValue(pickupNextOpeningState);
  const deliveryNextOpening = useRecoilValue(deliveryNextOpeningState);
  const [closingTime, setClosingTime] = useState<string | undefined>();

  const refreshDialogDisplayLogic = useRecoilCallback(({ set }) => () => {
    const day = moment().day();

    const cushion =
      handoverMethod === HandoverMethod.DELIVERY
        ? DELIVERY_CUSHION
        : PICKUP_CUSHION;

    const openingHours = Object.values(
      handoverMethod === HandoverMethod.DELIVERY ? deliveryHours : pickupHours
    )[day === 0 ? 6 : day - 1];

    const closingTime = openingHours.dinnerActive
      ? openingHours.dinnerTo
      : openingHours.lunchActive
      ? openingHours.lunchTo
      : undefined;

    setClosingTime(closingTime);

    if (closingTime) {
      if (
        moment().isBetween(
          moment(closingTime, 'HH:mm').subtract(cushion, 'minutes'),
          moment(closingTime, 'HH:mm')
        )
      ) {
        if (
          handoverMethod === HandoverMethod.DELIVERY &&
          closingSoonDialogSeen !== HandoverMethod.DELIVERY
        ) {
          set(closingSoonDialogOpenState, true);
          set(closingSoonDialogSeenState, HandoverMethod.DELIVERY);
        } else if (
          handoverMethod === HandoverMethod.PICKUP &&
          closingSoonDialogSeen === false
        ) {
          set(closingSoonDialogOpenState, true);
          set(closingSoonDialogSeenState, HandoverMethod.PICKUP);
        }

        return;
      }

      if (moment().isAfter(moment(closingTime, 'HH:mm'))) {
        // If closingSoonDialog is open close it as we are about to show another dialog
        closingSoonDialogOpen && set(closingSoonDialogOpenState, false);

        // If we are in Delivery then check if the new dialog was already shown from that particular view
        if (
          handoverMethod === HandoverMethod.DELIVERY &&
          closedNowDialogSeen !== HandoverMethod.DELIVERY
        ) {
          // If it has not been show before, show it
          set(closedNowDialogOpenState, true);
          set(closedNowDialogSeenState, HandoverMethod.DELIVERY);
        } else if (
          handoverMethod === HandoverMethod.PICKUP &&
          closedNowDialogSeen === false
        ) {
          set(closedNowDialogOpenState, true);
          set(closedNowDialogSeenState, HandoverMethod.PICKUP);
        }

        return;
      }
    }
  });

  // refresh dialog display logic every 60 sec
  useEffect(() => {
    const intervalID = setInterval(refreshDialogDisplayLogic, 60000);
    return () => clearInterval(intervalID);
  }, [refreshDialogDisplayLogic]);

  // execute dialog display logic at initial render
  // useEffect(refreshDialogDisplayLogic, [handoverMethod]);
  useEffect(refreshDialogDisplayLogic, []);

  return (
    <Container className={styles[`PickupOrDelivery`]}>
      <Title>
        {handoverMethod === HandoverMethod.PICKUP ? 'Abholung' : 'Lieferung'}
      </Title>
      <Dialog
        open={closingSoonDialogOpen}
        onClose={() => {
          setClosingSoonDialogOpen(false);
        }}
        onConfirm={() => {
          setClosingSoonDialogOpen(false);
        }}
        confirmButtonText={
          handoverMethod === HandoverMethod.PICKUP
            ? '💨 Bin unterwegs!'
            : '🤔 Mol überlege!'
        }
      >
        <Info>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
          {handoverMethod === HandoverMethod.PICKUP ? (
            <span>
              Hey, wir schließen {moment(closingTime, 'HH:mm').fromNow()}. Die
              letzte Abholung ist jetzt gleich{' '}
              <span className={styles[`PickupOrDelivery__highlight`]}>
                um {closingTime} Uhr
              </span>
              . Wenn Sie noch bestellen müssen Sie sich sputen!
            </span>
          ) : (
            <span>
              Sorry, wir schließen bereits{' '}
              {moment(closingTime, 'HH:mm').fromNow()}. Eine Lieferbestellung
              muss mindestens {DELIVERY_CUSHION} Minuten vor Ende der
              Öffnungszeit aufgegeben werden. Eine Abholung im Restaurant ist
              noch{' '}
              <span className={styles[`PickupOrDelivery__highlight`]}>
                bis {closingTime} Uhr
              </span>{' '}
              möglich.
            </span>
          )}
        </Info>
        {handoverMethod === HandoverMethod.DELIVERY ? (
          <DeliveryTimes />
        ) : (
          <PickupTimes />
        )}
      </Dialog>
      <Dialog
        open={closedNowDialogOpen}
        onClose={() => {
          setClosedNowDialogOpen(false);
        }}
        onConfirm={() => {
          setClosedNowDialogOpen(false);
        }}
        confirmButtonText="🙏🏾 Bis demnächst!"
      >
        <Info>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sorry, wir haben{' '}
          {moment(closingTime, 'HH:mm').fromNow()} geschlossen. Ab{' '}
          <span className={styles[`PickupOrDelivery__highlight`]}>
            {handoverMethod === HandoverMethod.DELIVERY
              ? deliveryNextOpening
              : pickupNextOpening}
          </span>{' '}
          nehmen wir wieder Bestellungen entgegen
          {handoverMethod === HandoverMethod.DELIVERY
            ? ' und liefern unser Essen zu Euch nach Hause 🏡'
            : '.'}
        </Info>
        {handoverMethod === HandoverMethod.DELIVERY ? (
          <DeliveryTimes />
        ) : (
          <PickupTimes />
        )}
      </Dialog>
      <div className={styles['PickupOrDelivery__switch-wrapper']}>
        <ButtonField
          name="handoverMethod"
          className={styles['PickupOrDelivery__switch']}
          groupClassName={styles['PickupOrDelivery__switch-group']}
        >
          <ButtonField.Option
            value="pickup"
            className={styles['PickupOrDelivery__switch-button']}
            onClick={() => {
              setHandoverMethod(HandoverMethod.PICKUP);
              return true;
            }}
            label={() => (
              <div className={styles[`PickupOrDelivery__option`]}>
                <Icon
                  className={`${styles[`PickupOrDelivery__icon`]} ${
                    styles[`PickupOrDelivery__icon--pickup`]
                  }`}
                  svg={PaperBagIcon}
                />
                <div className={styles[`PickupOrDelivery__label`]}>
                  Abholung
                </div>
              </div>
            )}
            focusOnHover
          />
          <ButtonField.Option
            value="delivery"
            className={styles['PickupOrDelivery__switch-button']}
            onClick={() => {
              setHandoverMethod(HandoverMethod.DELIVERY);
              return true;
            }}
            label={() => (
              <div className={styles[`PickupOrDelivery__option`]}>
                <Icon
                  className={`${styles[`PickupOrDelivery__icon`]} ${
                    styles[`PickupOrDelivery__icon--delivery`]
                  }`}
                  svg={DeliveryManIcon}
                />
                <div className={styles[`PickupOrDelivery__label`]}>
                  Lieferung
                </div>
              </div>
            )}
            focusOnHover
          />
        </ButtonField>
      </div>
      <Container.Inner className={styles[`PickupOrDelivery__inner`]}>
        {handoverMethod === HandoverMethod.PICKUP ? <Pickup /> : <Delivery />}
      </Container.Inner>
    </Container>
  );
};

export default PickupOrDelivery;
