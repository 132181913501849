import React from 'react';

import styles from '../Container.module.scss';

export interface Props {
  className?: string;
  ref?: React.Ref<HTMLDivElement>;
}

const Inner: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { children, className } = props;
    return (
      <div
        className={`${styles[`Container__Inner`]} ${
          className ? `${className}` : ''
        }`}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

export default Inner;
