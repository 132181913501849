import React from 'react';
import { useRecoilValue } from 'recoil';

import useFormattedHours from 'hooks/useFormattedHours';
import pickupHoursState from 'state/pickupHours';

import styles from './PickupTimes.module.scss';

export interface Props {}

const PickupTimes: React.FC<Props> = () => {
  const hours = useRecoilValue(pickupHoursState);
  const formattedPickupHours = useFormattedHours(hours);

  return (
    <div className={styles[`PickupTimes`]}>
      <div className={styles[`PickupTimes__label`]}>
        Unsere Öffnungszeiten sind:
      </div>
      <ul className={styles[`PickupTimes__list`]}>
        {formattedPickupHours
          .filter((h) => h.val[0] !== 'geschlossen')
          .map((formattedHour) => (
            <li key={formattedHour.key} className={styles[`PickupTimes__item`]}>
              <strong className={styles[`PickupTimes__days`]}>
                {formattedHour.key}:
              </strong>
              {formattedHour.val.join(', ')}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default PickupTimes;
