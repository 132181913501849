import { selector } from 'recoil';
import { HandoverMethod } from 'server/src/app/order/types';

import subtotalState from 'state/subtotal';
import handoverMethodState from 'state/handoverMethod';

const DELIVERY_FREE_SHIPPING = Number(
  process.env.GATSBY_DELIVERY_FREE_SHIPPING
);

export default selector<boolean>({
  key: 'freeDeliveryApplies',
  get: ({ get }) => {
    const subtotal = get(subtotalState);
    const handoverMethod = get(handoverMethodState);

    if (
      handoverMethod === HandoverMethod.DELIVERY &&
      subtotal >= DELIVERY_FREE_SHIPPING
    ) {
      return true;
    }

    return false;
  },
});
