import { selector } from 'recoil';
import { HandoverMethod } from 'server/src/app/order/types';

import subtotalState from 'state/subtotal';
import handoverMethodState from 'state/handoverMethod';
import freeDeliveryAppliesState from 'state/freeDeliveryApplies';

const DELIVERY_COST = Number(process.env.GATSBY_DELIVERY_COST);

export default selector({
  key: 'subtotalAfterShipping',
  get: ({ get }) => {
    const subtotal = get(subtotalState);
    const handoverMethod = get(handoverMethodState);

    if (handoverMethod === HandoverMethod.PICKUP) {
      return subtotal;
    }

    // If basket is empty do not apply shipping cost
    if (subtotal === 0) {
      return subtotal;
    }

    const freeDeliveryApplies = get(freeDeliveryAppliesState);
    if (freeDeliveryApplies) {
      return subtotal;
    }

    return subtotal + DELIVERY_COST;
  },
});
