import React from 'react';
import { useRecoilValue } from 'recoil';

import useFormattedHours from 'hooks/useFormattedHours';
import deliveryHoursState from 'state/deliveryHours';

import styles from './DeliveryTimes.module.scss';

export interface Props {}

const DeliveryTimes: React.FC<Props> = () => {
  const hours = useRecoilValue(deliveryHoursState);
  const formattedDeliveryHours = useFormattedHours(hours);

  return (
    <div className={styles[`DeliveryTimes`]}>
      <div className={styles[`DeliveryTimes__label`]}>
        Unsere Lieferzeiten sind:
      </div>
      <ul className={styles[`DeliveryTimes__list`]}>
        {formattedDeliveryHours
          .filter((h) => h.val[0] !== 'geschlossen')
          .map((formattedHour) => (
            <li
              key={formattedHour.key}
              className={styles[`DeliveryTimes__item`]}
            >
              <strong className={styles[`DeliveryTimes__days`]}>
                {formattedHour.key}:
              </strong>
              {formattedHour.val.join(', ')}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default DeliveryTimes;
