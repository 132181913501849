import { atom, selector } from 'recoil';
import { HandoverMethod, PlaceOrderReqBody } from 'server/src/app/order/types';

import basketState from 'state/basket';
import handoverMethodState from 'state/handoverMethod';
import paymentMethodState from 'state/paymentMethod';
import totalState from 'state/total';

const isClient = typeof window !== 'undefined';

export interface HandoverFormValues {
  handoverAt?: string;
  handoverMethod?: HandoverMethod;
  deliveryAddress?: string;
  message?: string;
}

export interface PaymentFormValues {
  creditCardOwner?: string;
  customerName?: string;
  phoneNumber?: string;
  stripePaymentMethodId?: string;
  rememberPaymentCash: boolean;
}

export const handoverFormValuesState = atom<HandoverFormValues>({
  key: 'handoverFormValues',
  default: {},
});

export const paymentFormValuesState = atom<PaymentFormValues>({
  key: 'paymentFormValues',
  default: {
    rememberPaymentCash: false,
  },
  effects_UNSTABLE: [
    ({ onSet, setSelf }) => {
      onSet((param) => {
        const values = param as PaymentFormValues;

        if (isClient) {
          if (values.rememberPaymentCash) {
            localStorage.setItem('customerName', values.customerName ?? '');
            localStorage.setItem('phoneNumber', values.phoneNumber ?? '');
            localStorage.setItem(
              'rememberPaymentCash',
              String(values.rememberPaymentCash ?? false)
            );
          } else {
            localStorage.removeItem('customerName');
            localStorage.removeItem('phoneNumber');
            localStorage.removeItem('rememberPaymentCash');
          }
        }
      });
      setSelf((param) => {
        const values = param as PaymentFormValues;
        let customerName;
        let phoneNumber;
        let rememberPaymentCash;

        if (isClient) {
          customerName = localStorage.getItem('customerName') ?? undefined;
          phoneNumber = localStorage.getItem('phoneNumber') ?? undefined;
          rememberPaymentCash =
            Boolean(localStorage.getItem('rememberPaymentCash')) ?? undefined;
        } else {
          customerName = undefined;
          phoneNumber = undefined;
          rememberPaymentCash = false;
        }

        return { ...values, customerName, phoneNumber, rememberPaymentCash };
      });
    },
  ],
});

export default selector<PlaceOrderReqBody>({
  key: 'placeOrder',
  get: ({ get }) => {
    const basket = get(basketState);
    const handoverMethod = get(handoverMethodState);
    const paymentMethod = get(paymentMethodState);
    const handoverFormValues = get(handoverFormValuesState);
    const paymentFormValues = get(paymentFormValuesState);
    const total = get(totalState);

    return {
      basket,
      paymentMethod,
      ...handoverFormValues,
      ...paymentFormValues,
      handoverAt: handoverFormValues.handoverAt ?? '',
      handoverMethod,
      total,
    };
  },
});
