import React, { useState, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import {
  GoogleMap,
  GoogleMapProps,
  DirectionsRenderer,
  DirectionsRendererProps,
  DirectionsService,
  DirectionsServiceProps,
} from '@react-google-maps/api';

import { Order } from 'server/src/app/order/types';
import useFirebase from 'hooks/useFirebase';
import directionsState from 'state/directions';
import directionsRendererState from 'state/directionsRenderer';
import mapState from 'state/map';
import mapZoomState, { initialMapZoom } from 'state/mapZoom';
import mapCenterState, { initialMapCenter } from 'state/mapCenter';
import basketOpenState from 'state/basketOpen';

import useConfirmationQuery from './useConfirmationQuery';
import CallToAction from './components/CallToAction';
import Headquarter from './components/Headquarter';
import FinalDestination from './components/FinalDestination';
import mapStyles from './mapStyles';
import styles from './Confirmation.module.scss';

const mapOptions: GoogleMapProps['options'] = {
  styles: mapStyles,
  disableDefaultUI: true,
};

export interface Props {
  orderId: string;
}

const Confirmation: React.FC<Props> = ({ orderId }) => {
  const { site } = useConfirmationQuery();
  const { firebase } = useFirebase();
  const [order, setOrder] = useState<Order>();
  const map = useRecoilValue(mapState(orderId));
  const zoom = useRecoilValue(mapZoomState(orderId));
  const center = useRecoilValue(mapCenterState(orderId));
  const basketOpen = useRecoilValue(basketOpenState);
  const [defaultCenter, setDefaultCenter] = useState<
    google.maps.LatLng | google.maps.LatLngLiteral
  >({
    lat: Number(site?.siteMetadata?.lat),
    lng: Number(site?.siteMetadata?.lng),
  });
  const directionsRenderer = useRecoilValue(directionsRendererState(orderId));
  const directions = useRecoilValue(directionsState(orderId));
  const firstUpdate = useRef(true);

  const directionsRendererOptions: DirectionsRendererProps['options'] = {
    preserveViewport: true,
    suppressMarkers: true,
    polylineOptions: {
      strokeColor: site?.siteMetadata?.primaryColor,
      strokeOpacity: 0.6,
      strokeWeight: 5,
    },
  };

  const directionsServiceOptions: DirectionsServiceProps['options'] = {
    origin: 'THAMARAI+Restaurant+Heilbronn',
    destination: order?.deliveryAddress,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  const onMapLoad = useRecoilCallback(
    ({ set }) => (map: google.maps.Map) => {
      set(mapState(orderId), map);
    },
    []
  );

  const onMapZoomChanged = useRecoilCallback(
    ({ set }) => () => {
      if (map) {
        set(mapZoomState(orderId), map.getZoom());
      }
    },
    [map]
  );

  const onMapCenterChanged = useRecoilCallback(
    ({ set }) => () => {
      if (map) {
        set(mapCenterState(orderId), map.getCenter());
      }
    },
    [map]
  );

  const onDirectionsRendererLoad = useRecoilCallback(
    ({ set }) => (directionsRenderer: google.maps.DirectionsRenderer) => {
      if (map) {
        set(directionsRendererState(orderId), directionsRenderer);
      }
    },
    [map]
  );

  const putDirectionsOnMap = useRecoilCallback(
    ({ set }) => (directions: google.maps.DirectionsResult, status: string) => {
      if (status === 'OK' && directionsRenderer && map) {
        set(directionsState(orderId), directions);
      }
    },
    [directionsRenderer]
  );

  useEffect(() => {
    if (map && directions && directionsRenderer) {
      directionsRenderer.setMap(map);
      directionsRenderer.setDirections(directions);

      if (zoom === initialMapZoom && center === initialMapCenter) {
        map.fitBounds(directions.routes[0].bounds);
      }
    }
  }, [map, directionsRenderer, directions]);

  useEffect(() => {
    if (firebase) {
      const orderRef = firebase.database().ref(`thamarai/orders/${orderId}`);

      return orderRef.on('value', function (snapshot: any) {
        if (snapshot) {
          const updatedOrder = snapshot.val();
          setOrder(updatedOrder);
        }
      });
    }
  }, [firebase]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setDefaultCenter(center);
    }
  }, [center]);

  const mainStyle = useSpring({
    from: {
      opacity: 0,
      maxHeight: '100vh',
    },
    to: {
      opacity: basketOpen ? 1 : 0,
      maxHeight: basketOpen ? '100vh' : '1.6vh',
    },
  });

  return (
    <div className={styles[`Confirmation`]}>
      <animated.div className={styles[`Confirmation__main`]} style={mainStyle}>
        <GoogleMap
          mapContainerClassName={styles[`Confirmation__map`]}
          zoom={zoom}
          center={defaultCenter}
          options={mapOptions}
          onLoad={onMapLoad}
          onZoomChanged={onMapZoomChanged}
          onCenterChanged={onMapCenterChanged}
        >
          <FinalDestination orderId={orderId} />
          <Headquarter
            position={{
              lat: Number(site?.siteMetadata?.lat),
              lng: Number(site?.siteMetadata?.lng),
            }}
          />
          {order?.deliveryAddress && !directionsRenderer && (
            <DirectionsRenderer
              onLoad={onDirectionsRendererLoad}
              options={directionsRendererOptions}
            />
          )}
          {order?.deliveryAddress && !directions && (
            <DirectionsService
              options={directionsServiceOptions}
              callback={putDirectionsOnMap}
            />
          )}
        </GoogleMap>
        {order && (
          <>
            {/* <StatusBar className={styles[`Confirmation__status-bar`]} /> */}
            {/* <KeyFacts className={styles[`Confirmation__key-facts`]} /> */}
            {/* <SubTotals className={styles[`Confirmation__sub-totals`]} /> */}
          </>
        )}
      </animated.div>

      {order && (
        <CallToAction
          className={styles[`Confirmation__call-to-action`]}
          order={order}
        />
      )}
    </div>
  );
};

export default Confirmation;
