import React from 'react';
import { useRecoilValue } from 'recoil';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';
import CheckboxField from '@soosap/sushi/Form/CheckboxField';

import { HandoverMethod } from 'server/src/app/order/types';
import Container from 'atoms/Container';
import handoverMethodState from 'state/handoverMethod';

import styles from './Cash.module.scss';

export interface Props {}

const Cash: React.FC<Props> = () => {
  const handoverMethod = useRecoilValue(handoverMethodState);

  return (
    <FieldSet
      className={styles[`Cash`]}
      classNameTitle={styles[`Cash__title`]}
      title={
        handoverMethod === HandoverMethod.DELIVERY
          ? `Barzahlung bei Lieferung`
          : 'Bezahlung bei Abholung'
      }
    >
      <Container.Inner>
        <TextField
          name="customerName"
          label="Ihr Name"
          placeholder="z.B. Ludwig van Beethoven"
          required
          requiredError="Pflichtfeld"
        />
        <TextField
          name="phoneNumber"
          label="Ihre Telefonnummer"
          placeholder="z.B. 07131 405 11 70"
          required
          requiredError="Pflichtfeld"
        />
        <CheckboxField
          name="rememberPaymentCash"
          label="Eingaben merken für die nächste Bestellung"
          className={styles[`Cash__remember`]}
        />
      </Container.Inner>
    </FieldSet>
  );
};

export default Cash;
