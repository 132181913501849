import React from 'react';

import Container from 'atoms/Container';
import styles from './GiftCard.module.scss';

export interface Props {}

const GiftCard: React.FC<Props> = () => {
  return (
    <Container.Inner className={styles[`GiftCard`]}>
      <span>GiftCard</span>
    </Container.Inner>
  );
};

export default GiftCard;
